import React from 'react';
import header from './component/header/header'
import './App.css';
import { BrowserRouter as Router} from 'react-router-dom'

function App() {
  return (
    <Router>
      <div>我是一个应用</div>
    </Router>
  );
}

export default App;
